/**
 * Primarily, exports function:
 *
 * <pre>
 *    // returns a console-like object - requires a prefix that should be unique across app
 *    const logger = createLogger({ 
 *      prefix: 'ModuleName::ComponentName' //  suggested use of prefix -  module is optional, but 
 *                                          //  can be used to group components for log filtering
 *    })
 *    // ex usages:
 *    logger.debug('debug message', args)
 *    logger.log('log message', args)
 *    logger.info('info message', args)
 *    logger.warn('warn message', args)
 *    logger.error('error message', e)
 * </pre>
 *
 * Log can be configured via env variable:
 *
 * <pre>
 *   # if set to false, suppresses ALL logs except errors
 *   LOG_ENABLED=true
 *
 *   # if blank, has no effect...
 *   # if a comma-delimited list of strings is provided, will 'whitelist' log statements, only printing those with 'prefix' as value from list
 *   LOG_WHITELIST=ModuleName::ComponentName1,ModuleName::ComponentName2
 * </pre>
 *
 */

export enum LoggerLevel {
  debug = 'debug',
  log = 'log',
  info = 'info',
  warn = 'warn',
  error = 'error'
}

export type Logger = (...data: any[]) => void
export type LoggerFactory = (level: LoggerLevel) => Logger
export type AppLogger = { [key in LoggerLevel]: Logger }

/**
 * Config object for global logging options
 */
export type LoggerCfg = {
  /**
   * Used to create an alternate logger object (function) to use instead of `console`
   */
  altLoggerFactory?: LoggerFactory,
  enabled: boolean
  logLevel: LoggerLevel
  whitelist: string[]
  diagnostics: boolean
}

export type LoggerProps = {
  prefix?: string
}

// ===================================================================================================
// internals

const LOG_LEVELS = [
  LoggerLevel.debug,
  LoggerLevel.log,
  LoggerLevel.info,
  LoggerLevel.warn,
  LoggerLevel.error
]
const getLogLevel = (curr: string, returnNull?: boolean) => {
  const levelName = curr?.toLowerCase() as keyof typeof LoggerLevel
  const level = LoggerLevel[levelName]
  if (!level && returnNull) {
    return null
  }
  if (!level) {
    throw new Error('Invalid Log Level: ' + curr)
  }
  return level
}

const createLoggerConfig = (cfg?: Partial<LoggerCfg>) => {
  const IS_PROD = process.env.NODE_ENV === 'production'
  const LOG_ENABLED = process.env.NEXT_PUBLIC_LOG_ENABLED !== 'false'
  const LOG_LEVEL = process.env.LOG_LEVEL
  const LOG_DIAGNOSTICS = process.env.LOG_DIAGNOSTICS === 'true'
  const ENABLED_LOGS = [
    ...getLogWhitelist(process.env.LOG_WHITELIST),
    ...getLogWhitelist(process.env.NEXT_PUBLIC_LOG_WHITELIST)
  ]

  function getLogWhitelist(whitelist: string) {
    return whitelist === 'null' ? [] : (whitelist || '')
      .split(/\s*,\s*/)
      .filter(str => str.length > 0)
  }

  const config: LoggerCfg = {
    ...cfg,
    diagnostics: LOG_DIAGNOSTICS,
    logLevel: getLogLevel(LOG_LEVEL, true) ?? LoggerLevel.info,
    whitelist: ENABLED_LOGS,
    enabled: LOG_ENABLED
  }
  
  !IS_PROD && console.log('Logger config: ', config)
  config.diagnostics && console.log('Logger config from env: ', {
    FROM_PROCESS: {
      LOG_ENABLED: process.env.NEXT_PUBLIC_LOG_ENABLED,
      LOG_LEVEL: process.env.LOG_LEVEL,
      LOG_DIAGNOSTICS: process.env.LOG_DIAGNOSTICS,
      NEXT_PUBLIC_LOG_WHITELIST: process.env.NEXT_PUBLIC_LOG_WHITELIST,
      LOG_WHITELIST: process.env.LOG_WHITELIST
    }
  })

  return config
}

// may be set at runtime via configLogger
let loggerConfig: LoggerCfg
loggerConfig = createLoggerConfig()

// ===================================================================================================
// exported functions

/**
 * Returns a wrapper for console that adds log prefixing for filtering, and allows for
 * other centralized log manipulation/suppression/etc
 */
export const createLogger = (props: LoggerProps): AppLogger => {
  const { prefix } = props

  const isCallEnabled = (prefix: string, curr: Logger, level: LoggerLevel) => {
    if (level === LoggerLevel.error) {
      return true // always log errors
    }
    if (!loggerConfig.enabled) {
      loggerConfig.diagnostics && console.warn('DISABLED LOGGER (!LOG_ENABLED): ', { prefix, level })
      return false
    }

    if (loggerConfig.whitelist.length > 0) {
      const i = loggerConfig.whitelist.indexOf(prefix)
      const enabled = i >= 0
      if (!enabled) {
        loggerConfig.diagnostics && console.warn('DISABLED LOGGER (non-whitelisted): ', { prefix, level })
      }
      return enabled
    }
    const enabledLevelVal = LOG_LEVELS.findIndex(l => l === loggerConfig.logLevel)
    const currentLevelVal = LOG_LEVELS.findIndex(l => l === level)
    const enabled = currentLevelVal >= enabledLevelVal
    if (!enabled) {
      loggerConfig.diagnostics && console.warn('DISABLED LOGGER (log levels): ', {
        prefix,
        level,
        currentLevelVal: LOG_LEVELS[currentLevelVal],
        enabledLevelVal: LOG_LEVELS[enabledLevelVal]
      })
    }
    return enabled  // if white list is empty, permit all
  }

  const getLogToUse = (logger: Logger, level: LoggerLevel): Logger => {
    const logToUse = loggerConfig.altLoggerFactory && loggerConfig.altLoggerFactory(level)
    return logToUse || logger
  }

  const getLogPrefix = ({ prefix, level }: { prefix: string, level: LoggerLevel }) => {
    // quick n dirty formatted time-string
    const currTime = () => {
      const d = new Date()
      const f = (num: number, dig = 2) => {
        return String(num).padStart(dig, '0')
      }
      const h = d.getHours()
      const m = d.getMinutes()
      const s = d.getSeconds()
      const ms = d.getMilliseconds()

      return `${f(h)}:${f(m)}:${f(s)}:${f(ms, 3)}`
    }
    return `${currTime()} - (${level.toUpperCase()}) ${prefix}`
  }

  type MessageSepAndArgs = {
    message: string
    sep?: string
    args: []
  }

  /**
   * Parses logger args into a:
   * <ul>
   *   <li><b>message</b>: the logger message string (may be empty)</li>
   *   <li><b>sep</b>: the separator string (will be empty if message is empty)</li>
   *   <li><b>args</b>: the remaining args to send to logger (may be null or empty array)</li>
   * </ul>
   * @param data
   */
  const getMessageSepAndArgs = (...data: any[]): MessageSepAndArgs => {
    if (data.length === 0) {
      return { message: '', args: [] }
    }
    const [arg1, ...rest] = data
    let message, args
    if (typeof arg1 === 'string') {
      message = arg1
      args = rest
    } else {
      message = ''
      args = data
    }
    args = args.length === 1 ? args[0] : args.flat()
    const sep = message.length > 0 ? ' :: ' : '' // add non-blank separator for non-blank message
    return { message, sep, args }
  }

  return Object.keys(LoggerLevel)
    .map(level => (console as any)[level])
    .reduce((prev, logger) => {
      return {
        ...prev,
        [logger.name]: (...data: any[]) => {
          const level = getLogLevel(logger.name)
          if (isCallEnabled(prefix, logger, level)) {
            const logPrefix = getLogPrefix({ prefix, level })
            const logToUse = getLogToUse(logger, level)
            const { message, sep, args } = getMessageSepAndArgs(...data)
            if (!args || args.length === 0) {
              logToUse(`${logPrefix}${sep}${message}`)
            } else {
              logToUse(`${logPrefix}${sep}${message}`, args)
            }
          }
        }
      }
    }, { ...console })
}

/**
 * Allows setting global logger config (primarily used for injection during testing)
 *
 * @see LoggerCfg
 */
export const configLogger = (cfg: Partial<LoggerCfg>) => {
  loggerConfig = createLoggerConfig(cfg)
}
