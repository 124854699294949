import React from 'react'
import { Middleware } from 'redux'
import { Provider } from 'react-redux'
import {
  Action,
  combineReducers,
  configureStore,
  ThunkAction
} from '@reduxjs/toolkit'
import { createLogger as createReduxLogger } from 'redux-logger'
import reduxCatch from 'redux-catch'
import { createLogger } from '@/common/logger'
import cartReducer from '@/modules/cart/cartSlice'
import eventReducer from '@/modules/event/eventSlice'
import sessionReducer from '@/modules/session/sessionSlice'
import { ctliveApi } from './ctliveApi'

const isServerSide = typeof window === 'undefined'
const isTest = process.env.IS_JEST_TEST === 'true'
const isDevMode = process.env.NODE_ENV !== 'production'

const logger = createLogger({ prefix: 'Redux' })

logger.log('Redux config: ', {
  isServerSide,
  isTest,
  isDevMode
})

const preloadedState = process.env.TEST_REDUX_STATE ? JSON.parse(process.env.TEST_REDUX_STATE) : undefined
if (preloadedState) {
  logger.log('Setting init Redux state: ', preloadedState)
}

export function makeStore() {
  logger.log('Creating Redux store...')
  const rootReducer = combineReducers({
    cart: cartReducer,
    event: eventReducer,
    session: sessionReducer,
    [ctliveApi.reducerPath]: ctliveApi.reducer
  })
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => {
      const additionalMiddleware: Middleware[] = [
        ctliveApi.middleware,
        reduxCatch((error: Error) => {
          logger.error(error.message)
        })
      ]
      if (!isServerSide && !isTest) {
        additionalMiddleware.push(createReduxLogger())
      }
      const middleware = getDefaultMiddleware({
        immutableCheck: isDevMode
      }).concat(additionalMiddleware)
      // console.warn('ADDING MIDDLEWARE: ', middleware)
      return middleware
    },
    preloadedState,
    devTools: isDevMode
  })
}

export const store = makeStore()

export type AppState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,
  AppState,
  unknown,
  Action<string>>

type ReduxStoreWrapperProps = {
  devMode?: boolean
  clearAppState?: boolean
  children: React.ReactNode
}

const ReduxStoreWrapper = (props: ReduxStoreWrapperProps) => {
  const { children } = props

  return (
    <Provider store={store}>
      {children}
    </Provider>
  )
}

export default ReduxStoreWrapper
