import {
  useEffect,
  useMemo
} from 'react'
import { useSelector } from 'react-redux'
import {
  createSlice,
  Draft,
  PayloadAction
} from '@reduxjs/toolkit'
import type { AppState } from '@/app/redux'
import {
  Address,
  CartItem,
  CartItems,
  CustomerInfo
} from '@/modules/cart/types/cart'
import {
  PaymentData,
  PostTransactionPayload,
  PostTransactionResponse,
  StripeData
} from '@/modules/checkout/types/checkout'
import { createLogger } from '@/common/logger'

const logger = createLogger({ prefix: 'CartSlice' })

const validateCartItem = (cartItem: CartItem) => {
  const { product, option, quantity } = cartItem
  if (!product || !option || typeof quantity !== 'number') {
    logger.warn('Invalid Cart Item: ', cartItem)
    return false
  }
  if (quantity < 1) {
    logger.warn('Invalid Cart Item, invalid quantity: ', quantity)
    return false
  }
  return true
}

/**
 * Creates and returns a key for the CartItem if it is valid. If invalid, returns null
 */
const createCartItemKey = (cartItem: CartItem, doValidate = true) => {
  return doValidate && !validateCartItem(cartItem) ? null : `${cartItem.product?.sku || ''}-${cartItem.option?.id}`
}

const checkQuantity = (key: string, state: Draft<CartState>, cartItem: CartItem, checkCombined?: boolean) => {
  if (!key) {
    logger.warn(`Add/update Cart operation is invalid, key is null`)
    return false
  }
  const maxPer = cartItem.product.maxItemsPerPerson
  const hasMaxPer = typeof maxPer === 'number' && maxPer > 0
  const remaining = cartItem.option.remaining
  const hasRemaining = typeof remaining === 'number'

  let quantity
  if (checkCombined && state.items[key]) {
    const currItem = state.items[key]
    quantity = cartItem.quantity + currItem.quantity
  } else {
    quantity = cartItem.quantity
  }

  logger.info(`Performing max cart quantity checked: `, { maxPer, remaining, quantity })

  if (hasMaxPer && quantity > maxPer) {
    state.cartInvalidMessage = `Can not add ${cartItem.quantity} item(s) to Cart, exceeds limit of ${maxPer} per customer`
    logger.warn(state.cartInvalidMessage)
    return false
  }
  if (hasRemaining && quantity > remaining) {
    state.cartInvalidMessage = `Can not add ${cartItem.quantity} item(s) to Cart, exceeds limit of ${remaining} item(s) available for purchase`
    logger.warn(state.cartInvalidMessage)
    return false
  }

  return true
}

const updateTotalQuantity = (state: CartState) => {
  state.totalQuantity = Object
    .values(state.items)
    .map(item => item.quantity)
    .reduce((prev, curr) => prev + curr, 0)
}

const hasLen = (str?: string): boolean => {
  return str?.trim().length > 0
}

const isAddressValid = (obj: Address): boolean => {
  return !!obj && hasLen(obj.street) &&
    hasLen(obj.city) &&
    hasLen(obj.country) &&
    hasLen(obj.region) &&
    hasLen(obj.postalCode)
}

const isCustomerValid = (obj: CustomerInfo): boolean => {
  return !!obj && hasLen(obj.email) &&
    hasLen(obj.firstName) &&
    hasLen(obj.lastName) &&
    hasLen(obj.phoneNumber) &&
    isAddressValid(obj.address)
}

const isPaymentDataValid = (obj: PaymentData): boolean => {
  return obj?.paymentMethod?.holderName?.length > 0
}

// note this is actually WritableDraft<CartState> when invoked within reducer
const updateIsValid = (state: CartState) => {
  state.checkoutInvalidMessage = null

  const hasTotalQuantity = state.totalQuantity > 0
  const customerValid = isCustomerValid(state.customer)
  const paymentDataValid = isPaymentDataValid(state.payment)
  
  const temp = []
  if (!hasTotalQuantity) {
    logger.warn('Cart is invalid, hasTotalQuantity is false')
    temp.push(`No items currently in cart`)
  }
  if (!customerValid) {
    logger.warn('Cart is invalid, customer is invalid: ', state.customer)
    temp.push(`Customer information is incomplete`)
  }
  if (!paymentDataValid) {
    logger.warn('Cart is invalid, payment data is invalid: ', { ...state.payment })
    temp.push(`Payment information is incomplete`)
  }

  state.checkoutInvalidMessage = temp.length === 0 ? null : temp.join(' | ')
  state.isValid = hasTotalQuantity && customerValid && (paymentDataValid || state.orderTotal === 0)
}

const createEmptyAddress = (): Address => {
  return {
    street: '',
    city: '',
    postalCode: '',
    region: '',
    country: 'US'
  }
}

// =========================================================================================================

export type CartState = {
  /**
   * Is true when cart items is non-empty and has valid customer and payment data
   */
  isValid: boolean
  /**
   * Total quantity of all items currently in cart
   */
  totalQuantity: number
  /**
   * Total price of the all items
   */
  orderTotal: number
  /**
   * Message to display to user after a Cart action (i.e. a message why last Cart action was invalid)
   */
  cartInvalidMessage: string | null
  /**
   * Message to display to user after a Checkout action (i.e. a message why checkout screen is currently invalid)
   */
  checkoutInvalidMessage: string | null
  items: CartItems
  customer: CustomerInfo
  payment: PaymentData
  transactionRequest?: PostTransactionPayload
  transactionResponse?: PostTransactionResponse
}

const initCustomer: CustomerInfo = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  address: createEmptyAddress(),
  useAsShipping: true,
  shippingAddress: undefined
}

const initialState: CartState = {
  isValid: false,
  totalQuantity: 0,
  orderTotal: 0,
  cartInvalidMessage: null,
  checkoutInvalidMessage: `No items currently in cart`,
  items: {},
  customer: initCustomer,
  payment: {} as PaymentData,
  transactionRequest: null,
  transactionResponse: null
}

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart: (state, action: PayloadAction<CartItem>) => {
      const key = createCartItemKey(action.payload)
      const canAdd = checkQuantity(key, state, action.payload, true)
      if (canAdd) {
        const currItem = state.items[key] || { quantity: 0 } as CartItem
        state.items[key] = {
          ...action.payload,
          quantity: action.payload.quantity + currItem.quantity
        }
      }
      updateTotalQuantity(state)
      updateIsValid(state)
    },
    removeFromCart: (state, action: PayloadAction<CartItem>) => {
      const key = createCartItemKey(action.payload)
      if (key) {
        delete state.items[key]
      }
      updateTotalQuantity(state)
      updateIsValid(state)
    },
    clearCart: (state, action: PayloadAction) => {
      state.items = { ...initialState.items }
      updateTotalQuantity(state)
      updateIsValid(state)
    },
    updateCartItemShipping: (state, action: PayloadAction<CartItem>) => {
      const key = createCartItemKey(action.payload, false)
      if (key && state.items[key]) {
        state.items[key].shippingType = action.payload.shippingType
      }
      updateTotalQuantity(state)
      updateIsValid(state)
    },
    updateCartQuantity: (state, action: PayloadAction<CartItem>) => {
      const key = createCartItemKey(action.payload)
      const canAdd = checkQuantity(key, state, action.payload)
      if (canAdd && state.items[key]) {
        state.items[key] = action.payload
      }
      updateTotalQuantity(state)
      updateIsValid(state)
    },
    reduceCartQuantity: (state, action: PayloadAction<CartItem>) => {
      const key = createCartItemKey(action.payload)
      if (key && state.items[key]) {
        const currItem = state.items[key]
        if (currItem.quantity == 1) {
          delete state.items[key]
        } else {
          state.items[key] = {
            ...currItem,
            quantity: currItem.quantity - 1
          }
        }
      }
      updateTotalQuantity(state)
      updateIsValid(state)
    },
    updateCustomerInfo: (state, action: PayloadAction<Partial<CustomerInfo>>) => {
      if (action.payload) {
        for (const key of Object.keys(action.payload)) {
          if (key === 'address' || key === 'shippingAddress') {
            if (!state.customer[key]) {
              logger.warn(`Uninitialized customer.${key} instance - setting to empty Address`)
              state.customer[key] = createEmptyAddress() // ensure object is initialized to empty Address if needed
            }
            for (const k2 of Object.keys(action.payload[key])) {
              state.customer[key][k2] = action.payload[key][k2]
            }
          }
          else {
            state.customer[key] = action.payload[key]
          }
          
          if (key === 'useAsShipping') {
            state.customer.shippingAddress = action.payload[key] ? undefined : createEmptyAddress()
          }
        }
      } else {
        state.customer = initialState.customer
      }
      updateIsValid(state)
    },
    updatePaymentData: (state, action: PayloadAction<PaymentData>) => {
      state.payment = action.payload || initialState.payment
      updateIsValid(state)
    },
    updateOrderTotal: (state, action: PayloadAction<number>) => {
      state.orderTotal = action.payload;
      updateIsValid(state)
    },
    clearTransaction: (state) => {
      state.transactionRequest = null
      state.transactionResponse = null
    },
    newTransactionRequest: (state, action: PayloadAction<PostTransactionPayload>) => {
      state.transactionRequest = action.payload
      state.transactionResponse = null
    },
    setTransactionResponse: (state, action: PayloadAction<PostTransactionResponse>) => {
      state.transactionResponse = action.payload
    }
  }
})

export const getCartQuantitySelector = (state: AppState) => state.cart.totalQuantity
export const getCustomerInfoSelector = (state: AppState): CustomerInfo => state.cart.customer || initialState.customer
export const getAddressSelector = (state: AppState): Address => state.cart.customer?.address || initialState.customer.address
export const getPaymentDataSelector = (state: AppState): PaymentData => state.cart.payment || initialState.payment
export const isCartValidSelector = (state: AppState): boolean => state.cart.isValid === true // required for proper initial boolean return
export const getOrderTotal = (state: AppState): number => state.cart.orderTotal
export const getTransactionRequestSelector = (state: AppState): PostTransactionPayload | null => state.cart.transactionRequest
export const getTransactionResponseSelector = (state: AppState): PostTransactionResponse | null => state.cart.transactionResponse
export const getCartInvalidMessageSelector = (state: AppState): string => state.cart.cartInvalidMessage
export const getCheckoutInvalidMessageSelector = (state: AppState): string => state.cart.checkoutInvalidMessage

export const {
  addToCart, removeFromCart, clearCart,
  updateCartItemShipping, updateCartQuantity,
  reduceCartQuantity, updateCustomerInfo, updatePaymentData,
  clearTransaction, newTransactionRequest, setTransactionResponse,
  updateOrderTotal
} = cartSlice.actions

const toOpt = (cartItem: CartItem) => {
  return cartItem.product.options.find(o => o.id === cartItem.option?.id)
}


// this is only used internally for exported hooks
const _getCartItemsSelector = (state: AppState) => state.cart.items

export const useCartItems = (): CartItem[] => {
  const cartItemsObj = useSelector(_getCartItemsSelector)
  const cartItems = useMemo(() => {
    return Object.values(cartItemsObj)
  }, [cartItemsObj])

  useEffect(() => {
    logger.debug('Updated Cart Items:', cartItems)
  }, [cartItems])

  return cartItems
}



/**
 * Obtains Cart Price in pennies
 */
export const useCartPrice = () => {
  const cartItems = useCartItems()
  // noinspection UnnecessaryLocalVariableJS
  const cartPrice = useMemo(() => {
    return cartItems
      .map(cartItem => ({
        ...cartItem,
        opt: toOpt(cartItem)
      }))
      .map(obj => ({ ...obj, price: obj.opt.price }))
      .map(obj => (obj.quantity * obj.price))
      .reduce((prev, curr) => (prev + curr), 0)
  }, [cartItems])
  logger.log('cartPrice', { cartPrice })
  return cartPrice
}

export default cartSlice.reducer
