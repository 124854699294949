import { createSlice } from '@reduxjs/toolkit'
import { createLogger } from '@/common/logger'
import { AppState } from '@/app/redux'
import { StorefrontEventWithProducts } from '@/modules/event/getEvent'
import { useSelector } from 'react-redux'

const logger = createLogger({ prefix: 'EventSlice' })

export type EventState = {
  currentEvent: StorefrontEventWithProducts
}

const initialState: EventState = {
  currentEvent: null
}

const slice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    setEvent: (state, action) => {
      state.currentEvent = action.payload
    }
  },
})

export const {setEvent} = slice.actions

export const getEventSelector = (state: AppState): StorefrontEventWithProducts => state.event?.currentEvent

export const useEvent = (): StorefrontEventWithProducts => {
  const event = useSelector(getEventSelector)
  return event || { products: [] } as StorefrontEventWithProducts
}

export default slice.reducer