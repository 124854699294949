import type { AppProps } from 'next/app'
import { ThemeProvider } from '@mui/styles'
import ReduxStoreWrapper from '@/app/redux'
import theme from '@/common/theme'
import { useEffect } from 'react';

export default function MyApp({ Component, pageProps }: AppProps) {
  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) jssStyles.parentElement.removeChild(jssStyles);
  }, []);
  return (
    <ReduxStoreWrapper clearAppState={false} devMode={true}>
      <ThemeProvider theme={theme}>
        <Component {...pageProps} />
      </ThemeProvider>
    </ReduxStoreWrapper>
  )
}