import {
  createSlice,
  SerializedError
} from '@reduxjs/toolkit'
import { Session } from '@/modules/session/types/session'
import { AppState } from '@/app/redux'
import { createLogger } from '@/common/logger'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'

const logger = createLogger({ prefix: 'SessionSlice' })

export type SessionState = {} & Session
export type SessionError = { error: FetchBaseQueryError | SerializedError }
export type SessionResponse = SessionError | Session

const initialState: SessionState = { token: '' }

const slice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    cacheToken: (state, action) => {
      state.token = action.payload
    }
  },
})

export const cacheToken = slice.actions.cacheToken

export const getTokenSelector = (state: AppState): string => state.session?.token

export default slice.reducer