import {
  createApi,
  fetchBaseQuery
} from '@reduxjs/toolkit/query/react'
import { AppState } from './redux'
import { createLogger } from '@/common/logger'
import { EstimateTaxPayload, PostTransactionPayload, PostTransactionResponse, TaxEstimate } from '@/modules/checkout/types/checkout'

const logger = createLogger({ prefix: 'ctliveApi' })

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.CTLIVE_API_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as AppState).session.token;
    logger.warn('token')
    if (token) {
      headers.set('authorization', `Bearer ${token}`)
    } else {
      logger.warn('No token available for API call')
    }
    return headers
  }
})

export const ctliveApi = createApi({
  reducerPath: 'ctliveApi',
  baseQuery,
  endpoints: build => ({
    createAnonymousSession: build.mutation<{ token: string }, void>({
      query: () => ({
        url: 'registration/createAnonymousSession',
        method: 'POST',
        body: {
          clientId: 'storefront'
        }
      })
    }),
    estimateTax: build.mutation<TaxEstimate, EstimateTaxPayload>({
      query: (body) => ({
        url: 'registration/estimateTax',
        method: 'POST',
        body
      })
    }),
    postTransaction: build.mutation<PostTransactionResponse, PostTransactionPayload>({
      query: (body) => ({
        url: 'storefront/transaction',
        method: 'POST',
        body
      })
    }),
    resendEmail: build.mutation<{ emailId: string }, any>({
      query: (body) => ({
        url: 'email/resend',
        method: 'POST',
        body
      })
    })
  })
})

export const {
  useCreateAnonymousSessionMutation,
  useEstimateTaxMutation,
  usePostTransactionMutation,
  useResendEmailMutation
} = ctliveApi